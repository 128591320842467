import React, { useContext, useEffect } from 'react';
import { Redirect, Route, BrowserRouter as Router } from "react-router-dom";

import Login from './screens/LoginPage';
import Main from './screens/MainPage';
// import CesiumPage from './screens/Cesium'
import PrivacyPolicyPage from "./screens/PrivacyPolicy";

import { WindowContext } from "./contexts/WindowContext";
import { getWindowDimensions } from './core/functions';

function App() {
  const {setRatio} = useContext(WindowContext)

    useEffect(() => {
      function handleWindowResize() {
          setRatio(getWindowDimensions());
      }

      window.addEventListener('resize', handleWindowResize);

      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
  }, [setRatio]);

  // React.useEffect(()=>{
  // }, [localStorage.getItem('user')])

  // // start: websockets trial
  // const [state, setState] = React.useState({
  //   currentData: []
  // })
  

  // React.useEffect(()=>{
  //   const ws = new WebSocket("ws://127.0.0.1:8000/")
    
  //   ws.onopen = () => {
  //   };
  //   ws.onmessage = (event) => {
  //     setState({ currentData: JSON.parse(event.data) });
  //   };

  //   ws.onclose = () => {
  //   };

  // }, [])
  // end: websockets trial

  return (
      < Router >
        {/* Lungsod-dashboard */}
          <Route path="/privacy" exact component={PrivacyPolicyPage}/>
          <Route path="/" exact component={Login}/>
          <Route path="/main" exact component={Main}/>

          {/* if user is already logged in, login page will never show. even if he/she explicitly uses localhost:9002
              else if no user is logged in, any route  (localhost:9002/any_route) will redirect to localhost:9002/ */}
          {JSON.parse(localStorage.getItem('user'))?
          <Redirect from="/" to="/main"/>
          : null}

        {/* <Route path="/cesium" exact component={CesiumPage} /> */}
      </Router>
  );
}

export default App;