import React, { Suspense } from 'react';
import FallbackPage from '../FallbackPage';

// Dynamically import your components using React.lazy
const ReportAnIssueDashboard = React.lazy(() => import('../RaiPages'));
const SosDashboard = React.lazy(() => import('../SosPages'));
const TourismDashboard = React.lazy(() => import('../TourismPages'));

export default function Main() {
    const module = JSON.parse(localStorage.getItem('user')).department_office.replace("\n","") || "None";
    
    // Wrap your component rendering logic inside React.Suspense
    return (
        <Suspense fallback={<FallbackPage/>}>
            {module === "CDRRMO" ? <SosDashboard /> :
             module === "PSTMO" ? <ReportAnIssueDashboard /> :
             module === "CTO" ? <TourismDashboard /> : 
             <ReportAnIssueDashboard />}
        </Suspense>
    );
}
