import React, { useEffect, useRef, useState } from 'react';
import './LoadingBar.css';

const LoadingBar = ({ loadingPercentage }) => {
  const [animationState, setAnimationState] = useState(0);
  const animationFrameId = useRef(null);
  const isMounted = useRef(true);

  useEffect(() => {
    const animate = () => {
      if (!isMounted.current) return;
      setAnimationState((prev) => {
        if (prev >= 0.8) return prev; // Stop when reaching 0.8
        return prev + 0.05; // Increment by 0.05 each time
      });

      // Request next frame
      animationFrameId.current = requestAnimationFrame(animate);
    };

    if (!loadingPercentage) {
      // Start animation if no specific percentage is provided
      requestAnimationFrame(animate);
    }

    return () => {
      isMounted.current = false;
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    }; // Cleanup on unmount
  }, [loadingPercentage]);

  return (
    <div className="loading-bar" style={{ width: `${animationState * 100}%` }}>
      {/* Your loading indicator here */}
    </div>
  );
};

export default LoadingBar;
